import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddMedicalCustomerProps {
  firstName: string;
  Package: string;
  middleNames: string;
  lastName: string;
  initials: string;
  title: string;
  gender: string;
  dateOfBirth: string;
  issuecountry: string;
  passportNo: string;
  passportExpdate: string;
  residentialAddress: string;
  city: string;
  country: string;
  telephoneNumber: string;
  email: string;
  officeTelephone: string;
  employerName: string;
  employerAddress: string;
  dependants: string;
  maritalStatus: string;
  heightCm: string;
  weightKg: string;
  premium: number;
  insuranceExpiryDate: string;
  emergencyContactName: string;
  emergencyTelephoneNumber: string;
  medQuestionToSend: string;
  occupation: string;
  token: string;
  Fullname: string;
  InsuranceStatus: string;
  MedicalCustomersID?: number;
}

const baseURL = apiURL();

const AddNewMedicalCustomer = async (props: IAddMedicalCustomerProps) => {
  const token = props.token;
  let value = "";
  const data = {
    firstName: props.firstName,
    Package: props.Package,
    middleNames: props.middleNames,
    lastName: props.lastName,
    initials: props.initials,
    title: props.title,
    gender: props.gender,
    dateOfBirth: props.dateOfBirth,
    issuecountry: props.issuecountry,
    passportNo: props.passportNo,
    passportExpdate: props.passportExpdate,
    residentialAddress: props.residentialAddress,
    city: props.city,
    country: props.country,
    telephoneNumber: props.telephoneNumber,
    email: props.email,
    officeTelephone: props.officeTelephone,
    employerName: props.employerName,
    employerAddress: props.employerAddress,
    dependants: props.dependants,
    maritalStatus: props.maritalStatus,
    heightCm: props.heightCm,
    weightKg: props.weightKg,
    premium: props.premium,
    insuranceExpiryDate: props.insuranceExpiryDate,
    emergencyContactName: props.emergencyContactName,
    emergencyTelephoneNumber: props.emergencyTelephoneNumber,
    medQuestionToSend: props.medQuestionToSend,
    occupation: props.occupation,
  };
  try {
    const response = await axios.post(baseURL + "medicalCustomer", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    }
  }
  return value;
};
export default AddNewMedicalCustomer;
