import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import useToken from "../../useToken";
import { Box, Container } from "@mui/material";
import GetCustomerPayment, {
  ICustomerPaymentRow,
} from "../../../services/API/GetCustomerPayment";
import { BootstrapDialogTitle } from "../../../Common/BootstrapDialogTitle";
import { BootstrapDialog } from "../Medical/AddNewUserStepper";

interface IGLProps {
  PolicyNo: string;
  onClose: () => void;
}
export const CustomerPayment = (props: IGLProps) => {
  const token = useToken().token;
  const [data, setData] = React.useState<ICustomerPaymentRow[]>([]);
  useEffect(() => {
    GetCustomerPayment({ token, PolicyNo: props.PolicyNo }).then((response) => {
      if (response) {
        const newData = response.data;
        setData(newData);
      }
    });
  }, [token, props.PolicyNo]);
  const columns = useMemo<MRT_ColumnDef<ICustomerPaymentRow>[]>(
    () => [
      {
        accessorKey: "paymentAmount",
        header: "Amount Paid",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 50,
      },
      {
        accessorKey: "paymentDate",
        header: "Payment Date",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
        size: 50,
      },
      {
        accessorKey: "extraInformation",
        header: "Information",
        size: 50,
      },
    ],
    []
  );

  return (
    <Container>
      <BootstrapDialog
        open={true}
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown={true}
        hideBackdrop={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.onClose}
          headerColor={"forestgreen"}
        >
          {"Customer Payment History"}
        </BootstrapDialogTitle>
        {data && (
          <MaterialReactTable
            columns={columns}
            data={data}
            enablePagination={true}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10],
            }}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableHiding={false}
            enableRowNumbers={false}
            enableFilters={false}
          />
        )}
      </BootstrapDialog>
    </Container>
  );
};

export default CustomerPayment;
