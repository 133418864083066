import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress"; // Import for loading spinner
import apiURL from "../services/apiURL";
import useToken from "../components/useToken";

interface IUpload {
  handleClose: () => void;
  paymentType: number;
}
interface IMessage {
  header: string;
  message: string;
}

const UploadMBGLPayments = (props: IUpload) => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>("");
  const [error, setError] = useState<IMessage>({ header: "", message: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[0];
      // Validate file type
      if (
        uploadedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        uploadedFile.type === "application/vnd.ms-excel"
      ) {
        setFile(uploadedFile);
        setFileName(uploadedFile.name);
      } else {
        setError({
          header: "Invalid File",
          message: "Please upload a valid Excel file.",
        });
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const baseURL = apiURL();
  const token = useToken().token;

  const handleSubmit = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      setIsLoading(true); // Start loading
      try {
        await axios.post(
          `${baseURL}UploadPayment/UploadMBGLPayments?paymentType=${props.paymentType}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setError({ header: "Info", message: "File uploaded successfully!" });
      } catch (error: any) {
        setError({
          header: "Error",
          message: "Error uploading file. Please try again.",
        });
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setError({ header: "Error", message: "No file selected." });
    }
  };

  const handleCloseErrorDialog = () => {
    setError({ header: "", message: "" });
    props.handleClose();
  };

  return (
    <div>
      <Dialog open={true} onClose={props.handleClose}>
        <DialogTitle>{`Upload ${
          props.paymentType === 1 ? "Money Back" : "Group Life"
        } Payment`}</DialogTitle>
        <DialogContent>
          <div
            {...getRootProps()}
            style={{
              border: "1px dashed black",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <input {...getInputProps()} />
            <p>
              {fileName
                ? `Selected File: ${fileName}`
                : "Drag 'n' drop an Excel file here, or click to select one"}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!file || isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
      {error.message && (
        <Dialog open={true} onClose={handleCloseErrorDialog}>
          <DialogTitle>{error.header}</DialogTitle>
          <DialogContent>{error.message}</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default UploadMBGLPayments;
