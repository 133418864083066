import apiURL from "../apiURL";
import axios from "axios";

const baseURL = apiURL();

export interface ICustomerRow {
  id?: number;
  fullName?: string;
  address?: string;
  email?: string;
  dateOfBirth?: string; // ISO string format: "YYYY-MM-DD"
  ministryDepartment?: string;
  nin?: string;
  occupation?: string;
  telephoneNumber?: string;
  marketerCode?: string;
  pinCode?: string;
  avatarUrl?: string;
  monthlyPremium?: number;
  enrolmentDate?: string;
  premiumEffectiveDate?: string;
  policyTerm?: number;
  policyPayingTerm?: string;
  customerPINCode?: string;
  markedAsPrinted?: boolean;
  whoPrintNote?: string;
}

export interface ICustomerData {
  data: ICustomerRow[];
}

export interface IDependentRow {
  id: number;
  customerId: number;
  fullName: string;
  fullAddress: string;
  relationshipToCustomer: string;
  sharePercentage: number;
  dateOfBirth?: string; // ISO string format: "YYYY-MM-DD"
  nin?: string;
  telephoneNumber?: string;
  trusteeName?: string;
  trusteeTelephoneNumber?: string;
}
export interface IDependentData {
  data: IDependentRow[];
}

export async function getAllCustomers(token: string) {
  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url: baseURL + `Customer`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as ICustomerData;
  } catch (error) {
    console.error("Failed to fetch customers:", error);
    throw error;
  }
}

export async function getCustomerById(token: string, id: number) {
  try {
    const response = await axios({
      method: "get",
      url: `${baseURL}/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch customer:", error);
    throw error;
  }
}

export async function addCustomer(token: string, customer: ICustomerRow) {
  try {
    const response = await axios.post(
      baseURL + `Customer/addCustomer`,
      customer,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to add customer:", error);
    throw error;
  }
}

export async function getDependentsByCustomerId(
  token: string,
  customerId: number
) {
  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url: `${baseURL}Customer/dependents?customerId=${customerId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IDependentData;
  } catch (error) {
    console.error("Failed to fetch dependents:", error);
    throw error;
  }
}

export async function addDependent(
  token: string,
  customerId: number,
  dependent: IDependentRow
) {
  try {
    const response = await axios.post(
      `${baseURL}Customer/${customerId}/dependents`,
      dependent,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to add dependent:", error);
    throw error;
  }
}
