// src/components/CustomerList.tsx

import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  Avatar,
  Button,
  Box,
  Container,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  ICustomerRow,
  getAllCustomers,
} from "../../../services/API/MoneyBackApi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomerForm from "./CustomerForm";
import useToken from "../../useToken";
import GetImage from "../../../services/API/GetImage";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../../services/API/signin";
import { ExportToCsv } from "export-to-csv";
import UploadMBGLPayments from "../../../Common/UploadMBGLPayments";
import InfoIcon from "@mui/icons-material/Info";
import { IDialogSave } from "../FrontDeskPayment/PaymentData";
import AlertDialog from "../../../Common/AlertDialog";
import PrintedMoneyBackCard from "../../../services/API/PrintedMoneyBackCard";

const CustomerList: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomerRow[]>([]);
  const [open, onClose] = useState(false);
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [rowSelection, setRowSelection] = useState<ICustomerRow | undefined>(
    undefined
  );
  const [openUpload, setOpenUpload] = useState(false);
  const [, setLoading] = React.useState(false);
  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const data = await getAllCustomers(token);
      const filteredData = data.data.filter(
        (row) =>
          !(
            row.ministryDepartment === "SLICO" &&
            userData.CanViewMoneyBack === "False"
          )
      );
      if (filteredData) {
        const subfolder = "MoneyBack";
        const customerImage = await Promise.all(
          filteredData.map(async (row) => {
            try {
              const imageName = row.avatarUrl;
              if (imageName && imageName !== null) {
                const imageResponse = await GetImage({
                  token,
                  subfolder,
                  imageName,
                });
                if (imageResponse) {
                  const blob = new Blob([imageResponse.data], {
                    type: "image/jpeg" || "image/png",
                  });
                  const imageUrl = URL.createObjectURL(blob);
                  return { ...row, avatarUrl: imageUrl };
                }
              }
            } catch (error) {
              console.error(
                `Error fetching image for employee ${row.id}:`,
                error
              );
            }
            return row;
          })
        );
        setCustomers(customerImage);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };
  const [reload, setReload] = React.useState(false);

  useEffect(() => {
    fetchCustomers();
    setReload(false);
  }, [reload]);

  const handleAdd = () => {
    onClose(true);
  };

  const handleDone = (val: boolean) => {
    if (val) fetchCustomers();
    handleClose();
  };
  const handleClose = () => {
    setRowSelection(undefined);
    onClose(false);
  };
  const columns = useMemo<MRT_ColumnDef<ICustomerRow>[]>(
    () => [
      {
        header: "Image",
        accessorKey: "avatarUrl",
        Cell: ({ cell }) => (
          <Avatar
            src={cell.getValue() as string}
            alt="Customer Avatar"
            sx={{ width: "2rem", height: "2rem" }}
          />
        ),
      },
      {
        accessorKey: "customerPINCode",
        header: "PIN Code / Policy #",
        accessorFn: (row) => row.customerPINCode || row.pinCode,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        header: "Full Name",
        accessorKey: "fullName",
      },
      {
        header: "Address",
        accessorKey: "address",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        accessorKey: "idCode",
        header: "Info",
        Cell: ({ cell }) =>
          !cell.row.original.markedAsPrinted ? (
            <Button
              color="error"
              disabled={cell.row.original.markedAsPrinted}
              variant="contained"
              sx={{ width: 150, height: 25, transform: "none" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click event
                handleApprove(
                  String(cell.row.original.pinCode),
                  String(cell.row.original.fullName)
                );
              }}
            >
              Mark as Printed
            </Button>
          ) : (
            <Tooltip title={cell.row.original.whoPrintNote} arrow>
              <IconButton
                size="small"
                sx={{
                  marginLeft: "0.5rem",
                  color: "#fff",
                }}
              >
                <InfoIcon
                  color="success"
                  sx={{
                    width: 50,
                    height: 20,
                  }}
                />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    []
  );

  const handleApprove = (pinCode: string, fullname: string) => {
    setShowDaily({
      open: true,
      headerText: `Money Back ID Card Printed`,
      mainText: `You are about to confirm that the ID code "${pinCode}" for Money Back customer "${fullname}" has been printed.`,
    });
    setPinCodeToPrinted(pinCode);
  };
  const [pinCodeToPrinted, setPinCodeToPrinted] = React.useState<string>();

  const [showDaily, setShowDaily] = React.useState<IDialogSave>({
    open: false,
    headerText: "",
    mainText: "",
  });

  const handleCancel = () => {
    setShowDaily({
      open: false,
      headerText: "",
      mainText: "",
    });
  };
  const HandleRowSelected = (row: ICustomerRow) => {
    onClose(true);
    setRowSelection(row);
  };

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    const filteredData = customers.map((item) => ({
      "PIN Code": item.pinCode,
      Fullname: item.fullName,
      Address: item.address,
      Email: item.email,
      "Date Of Birth": item.dateOfBirth,
      "Monthly Premium": item.monthlyPremium,
      "Effective Date": item.premiumEffectiveDate,
      "Policy Term": item.policyTerm,
    }));
    csvExporter.generateCsv(filteredData);
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
  };
  const handleUpload = () => {
    setOpenUpload(true);
  };

  const handleSubmit = () => {
    if (pinCodeToPrinted) {
      PrintedMoneyBackCard({
        pinCodeToPrinted: pinCodeToPrinted,
        acknowledgedBy: userData.DisplayName,
        token: token,
      });
      setShowDaily({
        open: false,
        headerText: "",
        mainText: "",
      });
      setReload(true);
    }
  };
  return (
    <Container sx={{ paddingTop: 10, grow: 1 }}>
      <MaterialReactTable
        columns={columns}
        data={customers}
        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
        enableDensityToggle={false}
        initialState={{
          density: "compact",
          pagination: {
            pageIndex: 0,
            pageSize: window.innerHeight > 720 ? 15 : 10,
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => HandleRowSelected(row.original),
          sx: { cursor: "pointer" },
        })}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20, 50],
        }}
        muiTableContainerProps={{
          sx: {
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              height: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1976d2",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#1976d2",
            },
          },
        }}
        enableFullScreenToggle={false}
        enableHiding={false}
        rowCount={customers.length}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem", // Reduced gap
              p: "0.3rem", // Reduced padding
              flexWrap: "wrap",
              height: "50px", // Reduced height
            }}
          >
            <Button
              color="success"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleAdd}
              startIcon={<GroupAddIcon />}
              variant="outlined"
            >
              {`Add Customer`}
            </Button>
            <Button
              color="primary"
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              onClick={handleExportData}
            >
              Export Data
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<FileUploadIcon />}
              onClick={handleUpload}
            >
              Upload Bulk Payments
            </Button>
          </Box>
        )}
      />
      {open && (
        <CustomerForm
          onClose={handleClose}
          doneSaving={handleDone}
          customer={rowSelection}
        />
      )}
      {openUpload && (
        <UploadMBGLPayments handleClose={handleUploadClose} paymentType={1} />
      )}
      {showDaily && (
        <AlertDialog
          open={showDaily.open}
          setOpen={handleCancel}
          setSave={handleSubmit}
          headerText={showDaily.headerText}
          mainText={showDaily.mainText}
        />
      )}
    </Container>
  );
};

export default CustomerList;
