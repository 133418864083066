import axios from "axios";
import apiURL from "../apiURL";

export interface ICustomerPaymentRow {
  PaymentDate: Date;
  status: string;
  PaymentAmount: number;
}

export interface ICustomerPaymentData {
  data: ICustomerPaymentRow[];
}
interface IGetUser {
  token: string;
  PolicyNo: string;
}
const baseURL = apiURL();
const GetCustomerPayment = async (props: IGetUser) => {
  const token = props.token;
  const data = await axios({
    method: "get",
    responseType: "json",
    url: baseURL + `Customer/GetCustomerPayment?PolicyNo=${props.PolicyNo}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data as ICustomerPaymentData;
};
export default GetCustomerPayment;
