import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  LinearProgress,
} from "@mui/material";
import useToken from "../../useToken";
import AlertDialog from "../../../Common/AlertDialog";
import { IDialogSave } from "../FrontDeskPayment/PaymentData";
import AddNewGLUser from "./AddNewGLUser";
import { IGroupLifeDataRow } from "../../../services/API/GetGroupLifeData";
import SearchGroupLife from "../../../services/API/SearchGroupLife";
import UploadMBGLPayments from "../../../Common/UploadMBGLPayments";

const SearchEngine: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [, setSearchResults] = useState<IGroupLifeDataRow[] | undefined>(
    undefined
  );
  const [openUpload, setOpenUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken().token;
  const [open, onClose] = useState(false);
  const [rowSelection, setRowSelection] = useState<
    IGroupLifeDataRow | undefined
  >(undefined);
  const [showDaily, setShowDaily] = React.useState<IDialogSave>({
    open: false,
    headerText: "",
    mainText: "",
  });

  const HandleRowSelected = (row: IGroupLifeDataRow) => {
    onClose(true);
    setRowSelection(row);
  };

  const handleSearch = async () => {
    if (searchValue !== "") {
      setIsLoading(true);
      // Make your API call here with searchValue
      try {
        const response = await SearchGroupLife({ token, searchValue });
        const data = await response;

        setSearchResults(data.data);

        if (data.data.length === 1) {
          // Automatically select the single result
          HandleRowSelected(data.data[0]);
        } else if (data.data.length > 1) {
          setShowDaily({
            open: true,
            headerText: `Information!`,
            mainText: `The are ${data.data.length} records matching "${searchValue}" Customer code. This will need to be fixed`,
          });
        } else {
          setShowDaily({
            open: true,
            headerText: `Information!`,
            mainText: `The are no records matching "${searchValue}" Customer code.`,
          });
        }
      } catch (error) {
        console.error("API call error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => {
    setSearchResults(undefined);
    setRowSelection(undefined);
    setSearchValue("");
    onClose(false);
  };

  const handleCancel = () => {
    setSearchResults(undefined);
    setRowSelection(undefined);
    setSearchValue("");
    setShowDaily({
      open: false,
      headerText: "",
      mainText: "",
    });
  };
  const handleUploadClose = () => {
    setOpenUpload(false);
  };
  const handleUpload = () => {
    setOpenUpload(true);
  };
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <div style={{ border: "1px solid lightgrey", padding: "10px" }}>
            <Typography variant="h5" gutterBottom color="green">
              Search Entire Group Life & Fambul Formal catalogue
            </Typography>
            <Typography variant="subtitle1" gutterBottom color="green">
              Enter the Customer code
            </Typography>
            <TextField
              label="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              variant="outlined"
              inputProps={{
                maxLength: 10,
              }}
            />
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
            {isLoading && <LinearProgress />}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ border: "1px solid lightgrey", padding: "10px" }}>
            <Typography variant="subtitle1" gutterBottom color="green">
              Upload Payment
            </Typography>
            <Button variant="contained" color="primary" onClick={handleUpload}>
              Upload Payment
            </Button>
          </div>
        </Grid>
      </Grid>
      {showDaily && (
        <AlertDialog
          open={showDaily.open}
          showNO={true}
          showYes={false}
          cancelText={"OK"}
          setOpen={handleCancel}
          headerText={showDaily.headerText}
          mainText={showDaily.mainText}
        />
      )}
      {open && rowSelection && (
        <AddNewGLUser
          openUser={open}
          onClose={handleClose}
          existingUser={rowSelection}
          isFambulLife={rowSelection.isFambulLife ? 1 : 0}
        />
      )}{" "}
      {openUpload && (
        <UploadMBGLPayments handleClose={handleUploadClose} paymentType={2} />
      )}
    </>
  );
};

export default SearchEngine;
