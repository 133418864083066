import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  Container,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import AddNewUserStep1 from "./AddNewUserStep1";
import AddNewUserStep2 from "./AddNewUserStep2";
import AddNewUserStep3 from "./AddNewUserStep3";
import { MedUserReducer } from "./MedUserReducer";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  IActiveData,
  steps,
} from "./AddNewUserStepper";
import { IMedicalCustomer } from "../../../services/API/GetMedicalData";
import AddNewMedicalCustomer, {
  IAddMedicalCustomerProps,
} from "../../../services/API/AddNewMedicalCustomer";
import useToken from "../../useToken";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MaritalOptions } from "./AddNewUserStateless";

interface IAddNewProps {
  openUser: boolean;
  onClose: (reload?: boolean) => void;
  existingUser?: IMedicalCustomer;
}

const AddNewUser = (props: IAddNewProps) => {
  const token = useToken().token;
  const {
    state,
    handleChange,
    handleDropDownChange,
    updateDependantNames,
    handleRadioChange,
    handleTextChange,
    updateStateForExistingUser,
  } = MedUserReducer();
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setError] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<IActiveData>({
    index: -1,
    stepComponent: <></>,
  });

  React.useEffect(() => {
    async function loadData() {
      if (props.existingUser) {
        await updateStateForExistingUser(props.existingUser);
      }
      setActiveStep({
        index: 0,
        stepComponent: (
          <AddNewUserStep1
            state={state}
            handleChange={handleChange}
            handleDropDownChange={handleDropDownChange}
          />
        ),
      });
      setLoaded(true);
    }
    loadData();
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  const handleErrrClose = () => {
    setError(false);
  };

  const componentToLoad: IActiveData[] = [
    {
      index: 0,
      stepComponent: (
        <AddNewUserStep1
          state={state}
          handleChange={handleChange}
          handleDropDownChange={handleDropDownChange}
        />
      ),
    },
    {
      index: 1,
      stepComponent: (
        <AddNewUserStep2
          state={state}
          handleChange={handleChange}
          handleDropDownChange={handleDropDownChange}
          updateDependantNames={updateDependantNames}
        />
      ),
    },
    {
      index: 2,
      stepComponent: (
        <AddNewUserStep3
          state={state}
          handleChange={handleChange}
          handleDropDownChange={handleDropDownChange}
          handleRadioChange={handleRadioChange}
          handleTextChange={handleTextChange}
        />
      ),
    },
  ];

  const handleNext = () => {
    const step = activeStep.index + 1;
    setActiveStep({
      index: step,
      stepComponent: componentToLoad[step].stepComponent,
    });
  };

  const handleBack = () => {
    const step = activeStep.index - 1;
    setActiveStep({
      index: step,
      stepComponent: componentToLoad[step].stepComponent,
    });
  };

  const okToSave = () => {
    let notCompleted = false;
    const states = state.dataToSave;
    if (
      states.title === "" ||
      states.initials === "" ||
      states.firstName === "" ||
      states.lastName === "" ||
      states.email === "" ||
      states.telephone === "" ||
      states.residentialAddress === "" ||
      states.country === "" ||
      states.weightKg === "" ||
      states.heightCm === "" ||
      states.city === "" ||
      states.emergencyContactName === "" ||
      states.emergencyTelephoneNumber === "" ||
      states.occupation === "" ||
      states.officeTelephone === "" ||
      states.employerName === "" ||
      states.employerAddress === ""
    ) {
      notCompleted = true;
    }
    return notCompleted;
  };

  const handleSave = async () => {
    if (okToSave()) {
      setError(true);
    } else {
      const states = state.dataToSave;
      setLoading(true);
      const medQuestionToSend = states.medQuestions.reduce(
        (acc, curr, index) => {
          const resp = states.medResponses[index]
            ? `${states.medResponses[index]}`
            : "NotAdded";
          const selected = states.medSelectedValues[index]
            ? states.medSelectedValues[index]
            : "NotSelected";
          const str = `${curr.medicalQuestionID}|${selected}¬${resp}`;
          return index === 0 ? str : `${acc},${str}`;
        },
        ""
      );

      const dependants = states.dependantNames
        .map((dep, i) => {
          return `${i}|${dep.fullname}¬${dep.relationship}#${
            dep.sex === "" ? "male" : dep.sex
          }>${dep.dob}`;
        })
        .join(",");

      const dataToSend: IAddMedicalCustomerProps = {
        firstName: states.firstName,
        Package: states.Package === "" ? "SL Standard" : states.Package,
        middleNames: states.middleName,
        lastName: states.lastName,
        initials: states.initials,
        title: states.title,
        gender: states.Sex === "" ? "male" : states.Sex,
        dateOfBirth: states.dateOB,
        issuecountry: states.issuecountry,
        passportNo: states.passportNo,
        passportExpdate: states.passportExpdate,
        residentialAddress: states.residentialAddress,
        city: states.city,
        country: states.country,
        telephoneNumber: states.telephone,
        email: states.email,
        officeTelephone: states.officeTelephone,
        employerName: states.employerName,
        employerAddress: states.employerAddress,
        dependants:
          dependants === "0|¬#female>,1|¬#female>,2|¬#female>,3|¬#female>" ||
          dependants === "0|¬#male>,1|¬#male>,2|¬#male>,3|¬#male>"
            ? ""
            : dependants,
        maritalStatus:
          states.MaritalStatus === "" ? "Married" : states.MaritalStatus,
        heightCm: states.heightCm,
        weightKg: states.weightKg,
        premium: states.premium,
        insuranceExpiryDate: states.insuranceExpiryDate,
        emergencyContactName: states.emergencyContactName,
        emergencyTelephoneNumber: states.emergencyTelephoneNumber,
        medQuestionToSend: medQuestionToSend,
        occupation: states.occupation,
        token: token,
        Fullname: "",
        InsuranceStatus: "",
        MedicalCustomersID: 0,
      };
      try {
        const response = await AddNewMedicalCustomer(dataToSend);
        if (response === "success") {
          setLoading(false);
          props.onClose(true);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loaded ? (
        <>
          <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
            <BootstrapDialog
              open={props.openUser}
              fullWidth
              maxWidth="md"
              disableEscapeKeyDown={true}
              hideBackdrop={true}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                {props.existingUser
                  ? `Existing Customer (${props.existingUser.policyNumber})`
                  : "Add New Customer"}
              </BootstrapDialogTitle>
              <div>
                <DialogContent dividers>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep.index}>
                      {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      {activeStep.index === 0 && (
                        <div>
                          <Container sx={{ mt: 2 }}>
                            <Box sx={{ width: 800, height: 430 }}>
                              <React.Fragment>
                                <Grid container spacing={2}>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="title"
                                      name="title"
                                      label="Title"
                                      fullWidth
                                      autoComplete="title"
                                      inputProps={{ maxLength: 9 }}
                                      disabled={state.dataToSave.editing}
                                      defaultValue={state.dataToSave.title}
                                      variant="standard"
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="initials"
                                      name="initials"
                                      label="Initials"
                                      fullWidth
                                      inputProps={{ maxLength: 9 }}
                                      autoComplete="initials"
                                      disabled={state.dataToSave.editing}
                                      defaultValue={state.dataToSave.initials}
                                      variant="standard"
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="Sex"
                                      defaultValue={
                                        state.dataToSave.Sex || "male"
                                      }
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        value="male"
                                        control={<Radio />}
                                        label="Male"
                                        disabled={state.dataToSave.editing}
                                      />
                                      <FormControlLabel
                                        value="female"
                                        control={<Radio />}
                                        label="Female"
                                        disabled={state.dataToSave.editing}
                                      />
                                    </RadioGroup>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="firstName"
                                      name="firstName"
                                      label="First name"
                                      fullWidth
                                      inputProps={{ maxLength: 40 }}
                                      defaultValue={state.dataToSave.firstName}
                                      autoComplete="given-name"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="middleName"
                                      name="middleName"
                                      defaultValue={state.dataToSave.middleName}
                                      label="Middle name"
                                      fullWidth
                                      inputProps={{ maxLength: 40 }}
                                      autoComplete="middle-name"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="lastName"
                                      name="lastName"
                                      defaultValue={state.dataToSave.lastName}
                                      label="Last name"
                                      fullWidth
                                      inputProps={{ maxLength: 40 }}
                                      autoComplete="family-name"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="email"
                                      name="email"
                                      defaultValue={state.dataToSave.email}
                                      label="Email"
                                      type="email"
                                      fullWidth
                                      inputProps={{ maxLength: 100 }}
                                      autoComplete="email-add"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      id="dateOB"
                                      name="dateOB"
                                      defaultValue={state.dataToSave.dateOB}
                                      label="Date Of Birth"
                                      type="date"
                                      sx={{ width: 220 }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="telephone"
                                      name="telephone"
                                      defaultValue={state.dataToSave.telephone}
                                      label="Telephone"
                                      fullWidth
                                      inputProps={{ maxLength: 20 }}
                                      autoComplete="tel-number"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="residentialAddress"
                                      name="residentialAddress"
                                      defaultValue={
                                        state.dataToSave.residentialAddress
                                      }
                                      label="Address"
                                      fullWidth
                                      inputProps={{ maxLength: 150 }}
                                      autoComplete="residentialAddress-line1"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="city"
                                      name="city"
                                      label="City"
                                      fullWidth
                                      inputProps={{ maxLength: 40 }}
                                      defaultValue={state.dataToSave.city}
                                      autoComplete="city"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="country"
                                      name="country"
                                      defaultValue={state.dataToSave.country}
                                      label="Country"
                                      inputProps={{ maxLength: 40 }}
                                      fullWidth
                                      autoComplete="res-country"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <form>
                                      <Autocomplete
                                        disablePortal
                                        id="MaritalStatus"
                                        defaultValue={
                                          state.dataToSave.MaritalStatus ||
                                          "Married"
                                        }
                                        options={MaritalOptions}
                                        sx={{ width: 250 }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Marital Status"
                                          />
                                        )}
                                        onChange={handleDropDownChange}
                                        disabled={state.dataToSave.editing}
                                      />
                                    </form>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="weightKg"
                                      name="weightKg"
                                      defaultValue={state.dataToSave.weightKg}
                                      label="Weight (Kg)"
                                      fullWidth
                                      inputProps={{ maxLength: 10 }}
                                      autoComplete="weightKg-cus"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="heightCm"
                                      name="heightCm"
                                      defaultValue={state.dataToSave.heightCm}
                                      label="Height (cm)"
                                      fullWidth
                                      inputProps={{ maxLength: 10 }}
                                      autoComplete="heightCm-cus"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="emergencyContactName"
                                      name="emergencyContactName"
                                      defaultValue={
                                        state.dataToSave.emergencyContactName
                                      }
                                      label="Emergency Contact"
                                      fullWidth
                                      inputProps={{ maxLength: 25 }}
                                      autoComplete="emergencyContactName-Contact"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <TextField
                                      required
                                      id="emergencyTelephoneNumber"
                                      name="emergencyTelephoneNumber"
                                      defaultValue={
                                        state.dataToSave
                                          .emergencyTelephoneNumber
                                      }
                                      label="Telephone"
                                      fullWidth
                                      inputProps={{ maxLength: 20 }}
                                      autoComplete="emergencyTelephoneNumber-ContTel"
                                      variant="standard"
                                      onChange={handleChange}
                                      disabled={state.dataToSave.editing}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="hasDependant"
                                          defaultChecked={
                                            state.dataToSave.hasDependant
                                          }
                                          onChange={handleChange}
                                          disabled={state.dataToSave.editing}
                                        />
                                      }
                                      label="Has Dependant"
                                    />
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            </Box>
                          </Container>
                        </div>
                      )}
                      {activeStep.index === 1 && activeStep.stepComponent}
                      {activeStep.index === 2 && activeStep.stepComponent}
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep.index === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          disabled={loading}
                          onClick={
                            activeStep.index === steps.length - 1 &&
                            state.dataToSave.editing
                              ? handleClose
                              : activeStep.index === steps.length - 1 &&
                                !state.dataToSave.editing
                              ? handleSave
                              : handleNext
                          }
                        >
                          {activeStep.index === steps.length - 1 &&
                          state.dataToSave.editing
                            ? "Close"
                            : activeStep.index === steps.length - 1 &&
                              !state.dataToSave.editing
                            ? "Add Customer"
                            : "Next"}
                        </Button>
                      </Box>
                    </React.Fragment>
                  </Box>
                </DialogContent>
              </div>
            </BootstrapDialog>
          </Container>
          {
            <Dialog open={errors} onClose={handleErrrClose}>
              <DialogTitle>Information</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The form is incomplete. Please make sure the following fields
                  are completed.
                </DialogContentText>
                <ul>
                  <li>Title</li>
                  <li>Initials</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Telephone</li>
                  <li>Residential Address</li>
                  <li>Country</li>
                  <li>Weight (kg)</li>
                  <li>Height (cm)</li>
                  <li>City</li>
                  <li>Emergency Contact Name</li>
                  <li>Emergency Telephone Number</li>
                  <li>Occupation</li>
                  <li>Office Telephone</li>
                  <li>Employer Name</li>
                  <li>Employer Address</li>
                </ul>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleErrrClose}>Ok</Button>
              </DialogActions>
            </Dialog>
          }
        </>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};
export default React.memo(AddNewUser);
