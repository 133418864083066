import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  addCustomer,
  addDependent,
  getDependentsByCustomerId,
  ICustomerRow,
  IDependentRow,
} from "../../../services/API/MoneyBackApi";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../Medical/AddNewUserStepper";
import GetAllMarketerUserData, {
  IAllMUserDataRow,
} from "../../../services/API/GetAllMarketerUserData";
import useToken from "../../useToken";
import resizeImage from "../../../Common/ResizeImage";
import uploadFile, { IUploadFile } from "../../../services/API/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomerPayment from "./CustomerPayment";

interface CustomerFormProps {
  customer?: ICustomerRow;
  onClose: () => void;
  doneSaving: (val: boolean) => void;
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  customer,
  onClose,
  doneSaving,
}) => {
  const token = useToken().token;
  const [customerData, setCustomerData] = useState<ICustomerRow | undefined>(
    customer
  );
  const [dependentsData, setDependentsData] = useState<IDependentRow[]>([]);
  const [marketerListOptions, setMarketerListOptions] = useState<
    IAllMUserDataRow[]
  >([]);
  const [selectedMarketer, setSelectedMarketer] = useState<string>();
  const [editing] = useState<boolean>(customer?.id ? customer?.id > 0 : false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [chosenFile, setChosenFile] = React.useState<File>();
  const [viewPayment, setPayment] = React.useState(false);

  const handleView = () => {
    setPayment(true);
  };

  const handleClosePayment = () => {
    setPayment(false);
  };

  useEffect(() => {
    const fetchMarketerPackage = (token: string) => {
      GetAllMarketerUserData({ token }).then((response) => {
        if (response) {
          setMarketerListOptions(response.data);
        }
      });
    };
    if (token) {
      fetchMarketerPackage(token);
    }
  }, [token]);

  useEffect(() => {
    if (token && customer?.id) {
      const customerId = customer?.id;
      const fetchDependents = (token: string) => {
        getDependentsByCustomerId(token, customerId).then((response) => {
          if (response) {
            setDependentsData(response.data);
          }
        });
      };
      fetchDependents(token);
    }
  }, [token]);

  const handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCustomerData((prevCustomerData) => {
      // Initialize customerData if undefined
      const updatedData: ICustomerRow = {
        ...prevCustomerData,
        [name]:
          name === "sharePercentage" || name === "someOtherNumberField"
            ? Number(value)
            : value,
      };

      return updatedData;
    });
  };

  const handleCustomerChangeTerm = (value: number) => {
    // Define payPeriod mapping based on policyTerm
    const payPeriodMap: { [key: string]: string } = {
      "8": "5 years",
      "10": "7 years",
      "12": "8 years",
      "15": "10 years",
      "20": "15 years",
    };

    // Get the corresponding payPeriod
    const payPeriod = payPeriodMap[value]; // Default to 0 if no match

    setCustomerData((prevCustomerData) => {
      const updatedData: ICustomerRow = {
        ...prevCustomerData,
        policyTerm: value, // Set the selected policy term
        policyPayingTerm: payPeriod, // Set the corresponding pay period
      };

      return updatedData;
    });
  };

  const handleFileInputChange = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      const file = fileInputRef.current.files[0];
      if (file.size > 30000) {
        resizeImage(file, 300, 300, 30, (resizedFile: File) => {
          const imageUrl = URL.createObjectURL(resizedFile);
          setPreviewImage(imageUrl);
          setChosenFile(resizedFile);
        });
      } else {
        const imageUrl = URL.createObjectURL(file);
        setPreviewImage(imageUrl);
        setChosenFile(file);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDependentChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedDependents = [...dependentsData];
    updatedDependents[index] = {
      ...updatedDependents[index],
      [e.target.name]: e.target.value,
    };
    setDependentsData(updatedDependents);
  };

  const handleAddDependent = () => {
    setDependentsData([
      ...dependentsData,
      {
        id: dependentsData.length + 1,
        customerId: customerData?.id || 0,
        trusteeName: "",
        fullName: "",
        fullAddress: "",
        relationshipToCustomer: "",
        sharePercentage: 0,
      },
    ]);
  };

  const handleRemoveDependent = (index: number) => {
    const updatedDependents = dependentsData.filter((_, i) => i !== index);
    setDependentsData(updatedDependents);
  };
  const readyToSave = () => {
    // Check if customerData and chosenFile are defined
    if (!customerData || !chosenFile) return false;

    // Array of required fields to check
    const requiredFields = [
      customerData.fullName,
      customerData.address,
      customerData.nin,
      customerData.email,
      customerData.dateOfBirth,
      customerData.occupation,
      customerData.ministryDepartment,
      customerData.telephoneNumber,
      customerData.marketerCode,
      customerData.monthlyPremium,
      customerData.policyTerm,
      customerData.enrolmentDate,
    ];

    // Check if all required fields are non-empty
    return requiredFields.every(
      (field) =>
        field !== undefined &&
        field !== null &&
        (typeof field === "string" ? field.trim() !== "" : true)
    );
  };

  const handleSave = async () => {
    try {
      if (customerData) {
        // Example API call to save customer data
        const savedCustomer = await addCustomer(token, customerData);

        const customerId = savedCustomer.id;
        const pinCode = savedCustomer.pinCode;
        if (chosenFile && customerId) {
          const dataToUpload: IUploadFile = {
            file: chosenFile,
            policy: pinCode,
            UploadFileTypeID: 17,
            token: token,
          };
          await uploadFile(dataToUpload);
        }

        const dependentPromises = dependentsData.map((dependent) =>
          addDependent(token, customerId, dependent)
        );

        await Promise.all(dependentPromises);
      }
      // Close the dialog after save
      doneSaving(true);
    } catch (error) {
      console.error("Error saving customer and dependents", error);
      // Handle error (e.g., display a notification)
    }
  };

  return (
    <Container>
      <BootstrapDialog
        open={true}
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown={true}
        hideBackdrop={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          wizard="true"
        >
          {"Customer Information"}
        </BootstrapDialogTitle>
        <Grid container spacing={1} sx={{ padding: 1 }}>
          <Grid item xs={12} sm={4} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                variant="circular"
                alt="fleet"
                src={previewImage || customer?.avatarUrl}
                style={{ width: 200, height: 200 }}
              />
              <input
                type="file"
                accept=".bmp, .jpeg, .jpg, .png"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleButtonClick}>
                  Upload Image
                </Button>
              </Box>
              {customerData && customerData.pinCode && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Box
                      component="span"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.success.dark,
                        borderRadius: "0.25rem",
                        color: "#fff",
                        maxWidth: "25ch",
                        p: "0.25rem",
                        marginRight: "10px", // Add margin for spacing between Box and TextField
                      })}
                    >
                      {customerData?.customerPINCode || customerData?.pinCode}
                    </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Button
                      color="success"
                      onClick={handleView}
                      startIcon={<VisibilityIcon />}
                      variant="outlined"
                    >
                      {`Payment History`}
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="fullName"
                  value={customerData?.fullName}
                  onChange={handleCustomerChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={customerData?.address}
                  onChange={handleCustomerChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="NIN"
                  name="nin"
                  value={customerData?.nin}
                  onChange={handleCustomerChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={customerData?.email || ""}
                  onChange={handleCustomerChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Date of Birth"
                  name="dateOfBirth"
                  type="date"
                  value={new Date(
                    customerData?.dateOfBirth || new Date()
                  ).toLocaleDateString("en-CA")}
                  onChange={handleCustomerChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Ministry/Department"
                  name="ministryDepartment"
                  value={customerData?.ministryDepartment || ""}
                  onChange={handleCustomerChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Occupation"
                  name="occupation"
                  value={customerData?.occupation || ""}
                  onChange={handleCustomerChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Telephone Number"
                  name="telephoneNumber"
                  value={customerData?.telephoneNumber || ""}
                  onChange={handleCustomerChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="marketer-label">Marketer</InputLabel>
                  <Select
                    labelId="marketer-label"
                    name="marketer"
                    label="Marketer"
                    value={selectedMarketer || customerData?.marketerCode || ""}
                    onChange={(e) => {
                      setSelectedMarketer(e.target.value);
                      handleCustomerChange({
                        target: {
                          name: "marketerCode",
                          value: e.target.value,
                        },
                      } as React.ChangeEvent<HTMLInputElement>);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {marketerListOptions?.map((option) => (
                      <MenuItem
                        key={option.marketerID}
                        value={option.marketerID}
                        style={{
                          fontSize: "12px",
                          padding: "5px 10px",
                          lineHeight: "1",
                        }}
                      >
                        {option.fullname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Premium"
                  name="monthlyPremium"
                  type="number"
                  value={customerData?.monthlyPremium || ""}
                  onChange={handleCustomerChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Enrolment Date"
                  name="enrolmentDate"
                  type="date"
                  value={new Date(
                    customerData?.enrolmentDate || new Date()
                  ).toLocaleDateString("en-CA")}
                  onChange={handleCustomerChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Effective Date"
                  name="premiumEffectiveDate"
                  type="date"
                  value={new Date(
                    customerData?.premiumEffectiveDate || new Date()
                  ).toLocaleDateString("en-CA")}
                  onChange={handleCustomerChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="policy-term-label">Term</InputLabel>
                  <Select
                    labelId="policy-term-label"
                    label="Term"
                    name="policyTerm"
                    value={customerData?.policyTerm || ""}
                    onChange={(event) =>
                      handleCustomerChangeTerm(Number(event.target.value))
                    }
                  >
                    <MenuItem value={8}>8 yrs</MenuItem>
                    <MenuItem value={10}>10 yrs</MenuItem>
                    <MenuItem value={12}>12 yrs</MenuItem>
                    <MenuItem value={15}>15 yrs</MenuItem>
                    <MenuItem value={20}>20 yrs</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Paying Term"
                  name="policyPayingTerm"
                  value={customerData?.policyPayingTerm || ""}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Customer PIN Code"
                  name="customerPINCode"
                  onChange={handleCustomerChange}
                  value={customerData?.customerPINCode || ""}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box padding={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Dependent(s) Details</Typography>
            {dependentsData.map((dependent, index) => (
              <Grid
                container
                spacing={0.1}
                key={index}
                sx={{
                  border: "1px solid #1565C0",
                  borderRadius: "10px",
                  padding: 0.5,
                }}
              >
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    value={dependent.fullName}
                    onChange={(e) => handleDependentChange(index, e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="fullAddress"
                    value={dependent.fullAddress}
                    onChange={(e) => handleDependentChange(index, e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Relationship"
                    name="relationshipToCustomer"
                    value={dependent.relationshipToCustomer}
                    onChange={(e) => handleDependentChange(index, e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Share (%)"
                    name="sharePercentage"
                    type="number"
                    value={dependent.sharePercentage}
                    onChange={(e) => handleDependentChange(index, e)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="DOB"
                    name="dateOfBirth"
                    type="date"
                    value={new Date(
                      dependent.dateOfBirth || new Date()
                    ).toLocaleDateString("en-CA")}
                    onChange={(e) => handleDependentChange(index, e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="NIN"
                    name="nin"
                    value={dependent.nin}
                    onChange={(e) => handleDependentChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Tel-Number"
                    name="telephoneNumber"
                    value={dependent.telephoneNumber}
                    onChange={(e) => handleDependentChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Trustee Name"
                    name="trusteeName"
                    value={dependent.trusteeName}
                    onChange={(e) => handleDependentChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Telephone"
                    name="trusteeTelephoneNumber"
                    value={dependent.trusteeTelephoneNumber}
                    onChange={(e) => handleDependentChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveDependent(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            {!editing && (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddDependent}
              >
                Add Dependent
              </Button>
            )}
          </Grid>
        </Box>
        <Divider sx={{ my: 2 }} />
        {!editing && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2 }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={!readyToSave()}
            >
              Save
            </Button>
          </Box>
        )}
        {viewPayment && customerData && customerData.pinCode && (
          <CustomerPayment
            PolicyNo={customerData.pinCode}
            onClose={handleClosePayment}
          />
        )}
      </BootstrapDialog>
    </Container>
  );
};

export default CustomerForm;
