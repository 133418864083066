import * as React from "react";
import Tab from "@mui/material/Tab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import MedicalData from "./MedicalData";
export default function IconLabelTabs() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div
      className="tabs-container"
      style={{ marginTop: "65px", position: "fixed", width: "100%", zIndex: 1 }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab icon={<GroupAddIcon />} label="Medical User List" value="1" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <main
            style={{
              height: window.innerHeight - 150 + "px",
              overflowX: "hidden",
              marginRight: "230px",
            }}
          >
            <MedicalData />
          </main>
        </TabPanel>
      </TabContext>
    </div>
  );
}
