import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IMedicalCustomer {
  fullname: string;
  medicalCustomersID: number;
  firstName: string;
  middleNames: string;
  lastName: string;
  initials: string;
  title: string;
  gender: string;
  dateOfBirth: string;
  issuecountry: string;
  passportNo: string;
  passportExpdate: string;
  residentialAddress: string;
  city: string;
  country: string;
  telephoneNumber: string;
  email: string;
  officeTelephone: string;
  employerName: string;
  employerAddress: string;
  dependants: string;
  maritalStatus: string;
  heightCm: string;
  weightKg: string;
  premium: number;
  package: string;
  insuranceStatus: string;
  insuranceExpiryDate: string;
  emergencyContactName: string;
  emergencyTelephoneNumber: string;
  medQuestionToSend: string;
  occupation: string;
  policyNumber: string;
}

export interface IMedicalData {
  data: IMedicalCustomer[];
}
interface IGetMedical {
  token: string;
}
const baseURL = apiURL();
const GetMedicalData = async (props: IGetMedical) => {
  const token = props.token;
  // Check session expiration before proceeding
  if (!checkSessionExpiration()) {
    return;
  }

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url: baseURL + "medicalCustomer",
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IMedicalData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};
export default GetMedicalData;
